import { AprData, InitialData, TvlData } from '@/src/app/api/types';
import { API_ENDPOINTS } from '@/src/app/constants/api';
import { testnetChainIds } from '@/src/app/constants/blockchain';
import { formatNumber } from '@/src/app/utils/formatNumber';
import { useMemo } from 'react';
import useSWR from 'swr';

export const useInfoData = (initialData: InitialData) => {
  const { data: tvls } = useSWR<TvlData[]>(API_ENDPOINTS.TVL, {
    fallbackData: initialData.tvls
  });
  const { data: aprs } = useSWR<AprData[]>(API_ENDPOINTS.APR, {
    fallbackData: initialData.aprs
  });

  const tvl: string | null = useMemo(() => {
    if (tvls?.length === 0) {
      return null;
    }
    const reducedTvl: number | null =
      tvls?.reduce((acc, item) => {
        if (item) {
          const { chainId, tvl, lptvl } = item;

          if (typeof chainId === 'number' && typeof tvl === 'number' && typeof lptvl === 'number') {
            return acc + (lptvl && !testnetChainIds.includes(chainId) ? tvl + lptvl : 0);
          }
        }
        return acc;
      }, 0) ?? null;
    return typeof reducedTvl === 'number' ? formatNumber(reducedTvl) : null;
  }, [tvls]);

  const bestApr: string | null = useMemo(() => {
    if (aprs?.length === 0) {
      return null;
    }
    const filteredData: number | null =
      aprs
        ?.filter((item) => {
          if (item) {
            const { chainId, holderapr } = item;
            return !testnetChainIds.includes(chainId) && holderapr;
          }
          return false;
        })
        ?.sort((a, b) => b.holderapr! - a.holderapr!)?.[0]?.holderapr ?? null;
    return typeof filteredData === 'number' ? formatNumber(filteredData, 2) : null;
  }, [aprs]);

  const avrLpApr: string | null = useMemo(() => {
    if (aprs?.length === 0) {
      return null;
    }
    const validAprs = aprs
      ?.filter((item) => {
        if (item) {
          const { chainId, lpapr } = item;
          return lpapr && !testnetChainIds.includes(chainId);
        }
        return false;
      })

      .filter(({ chainId, lpapr }) => lpapr && !testnetChainIds.includes(chainId));

    if (validAprs?.length === 0) {
      return null;
    }
    const reduceLpApr: number | null = validAprs?.reduce((acc, { lpapr }) => acc + (lpapr ?? 0), 0) ?? null;

    return typeof reduceLpApr === 'number' && validAprs ? formatNumber(reduceLpApr / validAprs.length, 2) : null;
  }, [aprs]);

  const infoData = [
    { title: 'TVL', value: tvl ? `USD ${tvl}` : null },
    { title: 'best APR', value: bestApr ? `${bestApr}%` : null },
    { title: 'Average LP APR', value: avrLpApr ? `${avrLpApr}%` : null }
  ];

  return { infoData };
};
